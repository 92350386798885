<template>
  <div id="gene-expression">
    <div class="gene-expression-con">
      <div class="gene-expression-con-id">
        <p>Job id: {{ task.task_id }}</p>
      </div>

      <div class="download">
                <span class="desp-text">
                    gene: {{ task.meta.gene.symbol }}
                </span>
        <a-tag color="orange" @click="download(csvUrl)">
          <a-icon type="cloud-download"/>
          download csv
        </a-tag>
      </div>

      <a-table
          :columns="columns"
          :data-source="goData"
          :loading="chartLoading"
          rowKey="biosample"
          :scroll="{ x: true }"
          :pagination="pagination"
      >
        <div
            slot="filterDropdown"
            slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
            style="padding: 0.5rem;"
        >
          <a-input
              v-ant-ref="c => (searchInput = c)"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              size="small"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button

              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >Search
          </a-button>
          <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
          >Reset
          </a-button>
        </div>
        <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
                <span v-if="searchText && searchedColumn === column.dataIndex">
                    <template
                        v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                        <mark
                            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                            :key="i"
                            class="highlight"
                        >{{ fragment }}</mark>
                        <template v-else>{{ fragment }}</template>
                    </template>
                </span>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>

      <a-tabs default-active-key="1" type="card" class="charts-list">
        <a-tab-pane key="1" tab="RPKM bar-plot" force-render>
          <div class="group-field-value">
            <!--                        <p>分组字段({{ task.meta.groupFiled }})值</p>-->
            <div class="group-field-value-con" ref="groupFieldValueConRef">
              <a-popover v-for="filedValue in Object.keys(groupFiledValue)" :key="filedValue"
                         style="max-height: 10vh;">
                <template slot="content">
                  <p v-for="item in groupFiledValue[filedValue]">{{ item }}</p>
                </template>
                <div
                    :class="['group-field-value-con-item',hideFiled.includes(filedValue) && 'group-field-value-con-item-selected']"
                    @click="toggleFiledStatus(filedValue)">
                  {{ filedValue }}
                </div>
              </a-popover>
            </div>
            <div class="group-field-value-overflow" v-if="djzkIsShow" @click="unfoldGroupField">
              点击展开
              <a-icon type="caret-down" v-if="!unfold"/>
              <a-icon type="caret-up" v-else/>
            </div>
          </div>
          <a-spin tip="Loading..." :spinning="chartLoading">
            <Chart echarts-id="gene-expression-chart" width="100%" :height="chartOption.height"
                   :option="geneExpression"/>
          </a-spin>
        </a-tab-pane>
        <a-tab-pane key="2" tab="RPKM violin-plot" force-render>
          <div class="group-field-value">
            <!--                        <p>分组字段({{ task.meta.groupFiled }})值</p>-->
            <div class="group-field-value-con" ref="groupFieldValueConRef">
              <a-popover v-for="filedValue in Object.keys(groupFiledValue)" :key="filedValue"
                         style="max-height: 10vh;">
                <template slot="content">
                  <p v-for="item in groupFiledValue[filedValue]">{{ item }}</p>
                </template>
                <div
                    :class="['group-field-value-con-item',hideFiled.includes(filedValue) && 'group-field-value-con-item-selected']"
                    @click="toggleFiledStatus(filedValue)">
                  {{ filedValue }}
                </div>
              </a-popover>
            </div>
            <div class="group-field-value-overflow" v-if="djzkIsShow" @click="unfoldGroupField">
              点击展开
              <a-icon type="caret-down" v-if="!unfold"/>
              <a-icon type="caret-up" v-else/>
            </div>
          </div>
          <a-spin tip="Loading..." :spinning="chartLoading">
            <p class="chart-title" v-if="violinPlotChart !== null">RPKM distribution</p>
            <div id="gene-expression-violin-chart" ref="violinRef"></div>
          </a-spin>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import "echarts/lib/chart/custom";
import "echarts/lib/component/tooltip";
import "echarts/extension/dataTool";
import prepareBoxplotData from "@/utils/prepareBoxplotData.js";
import Chart from "@/components/Chart.vue";
import GeneExpressionBoxPlot from "@/chartsOptions/geneExpressionBoxPlot.js";
import {parseCsv, downLoadCsv} from "@/utils/csv.js";
import {Violin} from "@antv/g2plot";
import {fetchSampleList} from "@/request/niu_api";

import {curveBasis, line} from "d3-shape";
import {scaleLinear} from "d3-scale";
import {scan, mean} from "d3-array";
import {color} from "d3-color";

const d3 = {
  curveBasis, line,
  scaleLinear, mean,
  scan,
  color
};

export default {
  name: "LayoutQueryExpressionOverViewGeneExpression",
  components: {
    Chart
  },
  data() {
    return {
      task: {},
      csvUrl: "",
      groupFiledValue: {},
      hideFiled: [], //隐藏了内容的字段
      allData: [], //保存全部数据
      goData: [], //表格数据
      columns: [], //表格表头数据
      sample: [],
      downloadData: [],
      pagination: {
        pageSize: 10,
        // current: 1,
        // total: 0,
        showSizeChanger: true,
        showTotal(total) {
          return `Total ${total} items`
        },
      },
      /* 表格搜索 */
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      /* 表格搜索 End */
      chartLoading: true,
      geneExpression: null,
      violinPlotChart: null,
      chartOption: {
        //width: "40vw",
        height: "50vh"
      },

      // 点击展开
      djzkIsShow: false,
      unfold: false
    };
  },
  created() {
    this.task = this.common.getLocalStorageItem("current_gene_expression_task");
    this.csvUrl = this.common.source_url + this.task.result.path + "/data.rpkm.csv";

    // 获取分组
    this.getGroupFiledValue(() => {
      parseCsv(this.csvUrl, (csv) => {
        this.allData = csv;
        this.goData = csv;

        this.setColumns();

        //进行筛选分析生成图表
        this.filterByGroup();
      })
    });
  },
  methods: {
    async getGroupFiledValue(callback) {
      const res = await fetchSampleList({
        ...this.task.sample_pms,
        page: 1,
        limit: 1000,
        sort: "biosample",
        order: "asc",
      })

      const dataSource = res.data.data;
      let obj = {};
      const groupFiled = this.task.meta.groupFiled;
      dataSource.forEach(ele => {
        if (ele[groupFiled] === "") return;

        const val = ele[groupFiled];

        if (Object.keys(obj).indexOf(val) === -1) {
          obj[val] = [ele.biosample];
        } else {
          obj[val].push(ele.biosample);
        }
      })

      //     //过滤分组字段空值
      //     Object.keys(obj).forEach(item => {
      //         if (obj[item].length === 0) {
      //             delete obj[item];
      //         }
      //     })

      this.groupFiledValue = obj;

      this.$nextTick(() => {
        const el = this.$refs.groupFieldValueConRef;
        if (el.clientHeight < el.scrollHeight) {
          this.djzkIsShow = true;
        }
      })

      callback();
    },
    unfoldGroupField() {
      if (this.unfold) {
        this.$refs.groupFieldValueConRef.style.maxHeight = "88px";
        this.unfold = false;
      } else {
        this.$refs.groupFieldValueConRef.style.maxHeight = this.$refs.groupFieldValueConRef.scrollHeight + "px";
        this.unfold = true;
      }
    },
    download() {
      const csvHead = `biosample,${this.task.meta.gene.symbol},${this.task.meta.groupFiled}\n`
      downLoadCsv(csvHead, this.downloadData, `基因表达量_${this.task.meta.gene.symbol}_${this.task.meta.gene.ensemble_id}`)
    },
    setColumns() {
      this.columns = [];
      Object.keys(this.goData[0]).forEach(ele => {
        if (ele === "__EMPTY") {
          this.columns.push({
            title: "geneID",
            dataIndex: "__EMPTY",
            width: 130,
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender'
            },
            onFilter: (value, record) => record.__EMPTY.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: visible => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus();
                });
              }
            }
          })
          return;
        }
        this.sample.push(ele);
        this.columns.push({
          title: ele,
          dataIndex: ele,
          width: 110,
          customRender: function (text, row, index) {
            // 判断text是否是number类型
            if (typeof text !== "number") {
              return text;
            }
            return text.toFixed(3)
          }
        })
      })
    },
    //筛选分析(按分组)
    filterByGroup() {
      let boxDataSet = [], xLabel = [];
      let violinData = [], violinMin = 0;

      //遍历分组数据,每个val是一个分组  //eg: val: control  infected
      Object.keys(this.groupFiledValue).filter(item => !this.hideFiled.includes(item)).forEach((val) => {
        //获取分组的数据 gene Array
        let groupValue = this.groupFiledValue[val];
        let gvArr = [];
        this.goData.forEach((item) => {
          //eg: item: {biosample:"SAMD00000728",cell_stage:"NA",log2_rpkm:31.1004707378425}
          if (groupValue.includes(item.biosample)) {
            const log2_rpkm = Number(item.log2_rpkm.toFixed(2))
            gvArr.push(log2_rpkm);

            //箱型图y轴刻度最小值
            // if (boxMin === 0 || log2_rpkm < boxMin) {
            //     const xs = log2_rpkm - Math.floor(log2_rpkm);
            //     if (xs > 0.5) {
            //         boxMin = Math.floor(log2_rpkm) + 0.5;
            //     } else {
            //         boxMin = Math.floor(log2_rpkm);
            //     }
            // }

            //小提琴图y轴刻度最大最小值
            if (violinMin === 0 || item.log2_rpkm < violinMin) {
              violinMin = Math.floor(item.log2_rpkm) - 5;
            }
            //设置小提琴图数据
            violinData.push({
              "x": val,
              "y": item.log2_rpkm,
            });

            //整理下载的数据
            this.downloadData.push([item.biosample, item.log2_rpkm, val]);
          }
        })

        boxDataSet.push(gvArr);
        xLabel.push(val);
      });

      const groupFiledValueLength = xLabel.length;
      //箱型图容器宽高
      //this.chartOption.width = this.common.vwToPxNum(groupFiledValueLength > 10 ? (groupFiledValueLength > 40 ? 80 : 60) : 40) + "px";
      this.chartOption.height = this.common.vwToPxNum(groupFiledValueLength > 10 ? (groupFiledValueLength > 40 ? 40 : 32) : 25) + 45 + "px";

      GeneExpressionBoxPlot.dataset[0].source = boxDataSet;
      GeneExpressionBoxPlot.dataset[1].transform.config.itemNameFormatter = function (p) {
        return xLabel[p.value]
      };
      GeneExpressionBoxPlot.yAxis.min = 0;
      // GeneExpressionBoxPlot.xAxis.axisLabel.rotate = groupFiledValueLength > 8 ? (groupFiledValueLength > 20 ? (groupFiledValueLength > 40 ? -40 : -20) : -10) : 0;
      const rotate = groupFiledValueLength > 8 ? (groupFiledValueLength > 20 ? (groupFiledValueLength > 40 ? 40 : 20) : 10) : 0;
      GeneExpressionBoxPlot.xAxis.axisLabel.rotate = rotate;

      const zoomEnd = groupFiledValueLength > 5 ? Math.ceil(5 / groupFiledValueLength * 100) : 100;
      GeneExpressionBoxPlot.dataZoom[0].end = zoomEnd;
      GeneExpressionBoxPlot.dataZoom[1].end = zoomEnd;

      // g2-plot画小提琴图
      // this.drawViolin(violinData, groupFiledValueLength, violinMin);
      // echarts 画小提琴图
      this.violinDraw(violinData, groupFiledValueLength, violinMin, rotate, zoomEnd);

      this.$nextTick(() => {
        this.generateChart();
      })
    },
    drawViolin(violinData, groupFiledValueLength, violinMin) {
      if (this.violinPlotChart !== null) {
        this.violinPlotChart.destroy();
      }
      //小提琴图
      this.violinPlotChart = new Violin('gene-expression-violin-chart', {
        data: JSON.parse(JSON.stringify(violinData)),
        width: this.common.vwToPxNum(groupFiledValueLength > 10 ? (groupFiledValueLength > 40 ? 80 : 60) : 40),
        height: this.common.vwToPxNum(groupFiledValueLength > 10 ? (groupFiledValueLength > 40 ? 40 : 32) : 25),
        xField: "x",
        yField: "y",
        shape: 'smooth', //设置小提琴图形状 'hollow-smooth' 'hollow', 'smooth'
        xAxis: {
          nice: true,
          top: true,
          line: {
            style: {
              stroke: '#aaa',
            },
          },
          tickLine: {
            style: {
              lineWidth: 1,
              stroke: '#aaa',
            },
            length: 3,
          },
          label: {
            rotate: groupFiledValueLength > 8 ? (groupFiledValueLength > 20 ? (groupFiledValueLength > 40 ? Math.PI / 2 : Math.PI / 4) : Math.PI / 8) : 0,
            style: {
              fontSize: 12,
              textAlign: groupFiledValueLength > 8 ? "left" : "center",
            },
            autoHide: true
          }
        },
        yAxis: {
          nice: true,
          top: true,
          title: {
            text: "log2(rpkm)"
          },
          minLimit: parseFloat(violinMin),
          line: {
            style: {
              stroke: '#aaa',
            },
          },
          tickLine: {
            style: {
              lineWidth: 1,
              stroke: '#aaa',
            },
            length: 3,
          }
        },
        scrollbar: {
          type: 'horizontal',
        },
        legend: false,
        // legend: {
        //   position:"right"
        // },
        theme: this.common.G2ChartTheme,
        // 不显示内部箱型
        box: false,
        // 自定义显示
        meta: {
          high: {
            alias: 'high',
            formatter: (v) => `${v.toFixed(2)}`,
          },
          low: {
            alias: 'low',
            formatter: (v) => `${v.toFixed(2)}`,
          },
          q1: {
            alias: 'q1',
            formatter: (v) => `${v.toFixed(2)}`,
          },
          q3: {
            alias: 'q3',
            formatter: (v) => `${v.toFixed(2)}`,
          },
          median: {
            alias: 'median',
            formatter: (v) => {
              return `${v[0].toFixed(2)}`
            },
          },
        },
        forceFit: true,
        interactions: [
          {
            type: 'slider',
            cfg: {
              start: 0.1,
              end: 0.45,
            },
          },
        ],
      });
      this.violinPlotChart.render();
    },
    // 自定义小提琴图所需两个函数
    kernelDensityEstimator(kernel, X) {
      return function (V) {
        return X.map(function (x) {
          return [
            x,
            d3.mean(V, function (v) {
              return kernel(x - v);
            })
          ];
        });
      };
    },
    kernelEpanechnikov(k) {
      return function (v) {
        return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
      };
    },
    violinDraw(violinData, groupFiledValueLength, violinMin, rotate, zoomEnd) {
      // 获取所有列数据
      const columns = Object.keys(this.groupFiledValue);
      // 获取对应列所有行数据
      const dataSource = columns.map(x =>
          violinData.filter(item => item.x === x).map(item => item.y)
      );

      const tooltipData = prepareBoxplotData(dataSource);
      const {boxData} = tooltipData;

      this.$refs.violinRef.style.width = "100%";
      this.$refs.violinRef.style.height = this.common.vwToPxNum(groupFiledValueLength > 10 ? (groupFiledValueLength > 40 ? 40 : 32) : 25) + "px"

      const myChart = echarts.init(this.$refs.violinRef);

      const option = {
        title: [
          {
            text: 'RPKM distribution',
            left: 'center'
          }
        ],
        grid: {
          containLabel: true,
          top: 30,
          left: 25,
          right: 10,
          bottom: '10%'
        },
        tooltip: {
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (param) {
            return [
              columns[param.dataIndex] + ": ",
              "high: " + boxData[param.dataIndex][4].toFixed(2),
              "Q3: " + boxData[param.dataIndex][3].toFixed(2),
              "median: " + boxData[param.dataIndex][2].toFixed(2),
              "Q1: " + boxData[param.dataIndex][1].toFixed(2),
              "low: " + boxData[param.dataIndex][0].toFixed(2)
            ].join("<br/>");
          }
        },
        toolbox: {
          show: true,
          right: 20,
          itemSize: 20,
          feature: {
            restore: {
              icon: "image:///images/echarts-toolbox-refresh.png"
            },
            saveAsImage: {
              show: true,                      // 是否显示工具
              title: '保存为图片',                // 工具标签
              icon: 'image:///images/echarts-toolbox-download.png',             // 工具 icon
              type: 'png',                     // 自定义保持图片的后缀
              // name:'RPKM distribution',                // 自定义保持图片的名称，默认获取 tite 标题的 text 内容作为文件名称
              backgroundColor:'#FFFFFF',          // 保持图片的背景颜色，默认白色
              connectedBackgroundColor: 'red', // 如果图表使用了 echarts.connect 对多个图表进行联动，则在导出图片时会导出这些联动的图表。该配置项决定了图表与图表之间间隙处的填充色。
              excludeComponents: ['toolbox'],  // 保持图片时，图片中忽略的组件列表
            },
          }
        },
        xAxis: {
          type: "category",
          data: columns,
          boundaryGap: true,
          nameGap: 30,
          splitArea: {
            show: false
          },
          axisLabel: {
            //强制显示所有标签
            interval: 0,
            rotate: rotate
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#aaa",
              width: 2
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          z: 2,
          type: "value",
          name: "log2(rpkm)",
          nameLocation: "middle",
          nameGap: 20,
          nameTextStyle: {color: "#595959"},
          min: Math.floor(violinMin - 2),
          axisLine: {
            show: true,
            lineStyle: {
              color: "#aaa",
              width: 2
            }
          },
          splitArea: {
            show: true
          }
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: zoomEnd
          },
          {
            show: true,
            type: 'slider',
            top: '90%',
            xAxisIndex: [0],
            start: 0,
            end: zoomEnd
          }
        ],
        series: [
          {
            type: "custom",
            name: "RPKM distribution",
            renderItem: (params, api) => {
              const categoryIndex = api.value(0);

              const min = Math.min(...dataSource[categoryIndex]);
              const max = Math.max(...dataSource[categoryIndex]);
              const liner = d3.scaleLinear().domain([min - 2, max + 1]).ticks(200);

              const density = this.kernelDensityEstimator(this.kernelEpanechnikov(0.85), liner)(dataSource[categoryIndex]);
              const maxDens = Math.max(...density.map(v => v[1]));

              // 左右边宽度各为85%
              const points = density.map(v => {
                const [y, dens] = v;
                const point = api.coord([categoryIndex, y]);
                point[0] += (((api.size([0, 0])[0] / 2) * dens) / maxDens) * 0.85;
                return point;
              });
              const points2 = density.map(v => {
                const [y, dens] = v;
                const point = api.coord([categoryIndex, y]);
                point[0] -= (((api.size([0, 0])[0] / 2) * dens) / maxDens) * 0.85;
                return point;
              });

              const lineGenerator = d3.line().curve(d3.curveBasis);
              const pathData = lineGenerator(points);
              const pathData2 = lineGenerator(points2);

              const color = this.common.getColor20(categoryIndex);

              return {
                z: 2,
                type: "path",
                shape: {
                  pathData: pathData + pathData2
                },
                colorBy: "data",
                style: api.style({
                  fill: color,
                  opacity: 0.45,
                  stroke: color,
                  lineWidth: 1
                }),
                styleEmphasis: api.style({
                  fill: "#B1D0FA",
                  stroke: color,
                  lineWidth: 2
                })
              };
            },
            encode: {
              x: 0,
              y: dataSource[
                  d3.scan(dataSource, function (a, b) {
                    return b.length - a.length;
                  })
                  ].map((v, i) => i + 1)
            },
            data: dataSource.map((v, i) => [i, ...v])
          }
        ]
      };

      myChart.setOption(option);
    },
    generateChart() {
      this.geneExpression = GeneExpressionBoxPlot;
      this.chartLoading = false;
    },
    //点击隐藏或显示该字段对应内容（图 + 表）
    toggleFiledStatus(filed) {
      this.chartLoading = true;

      setTimeout(() => {
        const bool = this.hideFiled.includes(filed);
        if (bool) {//显示的时候
          this.hideFiled.splice(this.hideFiled.findIndex(item => item === filed), 1);
        } else { //隐藏的时候
          this.hideFiled.push(filed);
        }

        let hideDataSource = this.hideFiled.map(item => {
          return this.groupFiledValue[item];
        });
        let hideData = hideDataSource.reduce((acc, cur) => acc.concat(cur), []);
        this.goData = this.allData.filter(data => !hideData.includes(data.biosample));

        //进行筛选分析生成图表
        this.filterByGroup();

        this.chartLoading = false;
      }, 10)
    },
    //表格搜索
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    //重置搜索
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
  },
}
</script>

<style lang="scss" scoped>
#gene-expression {
  position: relative;
  background: #F9F9FB;
  padding: 1.4rem 8rem;

  .gene-expression-con {
    padding: 2rem;
    background: #fff;

    &-id {
      border-bottom: 1px solid #E6E6E6;
      padding-bottom: 1rem;

      p {
        font-size: 1rem;
        color: #333;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    .download {
      display: flex;
      justify-content: end;
      padding: 40px 0;

      .desp-text {
        font-size: 19px;
        font-weight: 400;
        color: #333333;
        margin-right: 10px;
        line-height: 33px;
      }

      .ant-tag {
        cursor: pointer;
        height: 33px;
        line-height: 33px;
        font-size: 16px;
      }
    }

    .charts-list {
      display: unset !important;
    }

    .group-field-value {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      & > p {
        height: 23px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 19px;
      }

      &-con {
        display: flex;
        flex-wrap: wrap;
        max-height: 88px;
        overflow-y: hidden;
        transition: all 0.3s ease-in-out;

        &-item {
          display: inline-block;
          background: rgba(9, 127, 53, 0.06);
          border-radius: 5px 5px 5px 5px;
          font-size: 18px;
          font-weight: 400;
          color: #097F35;
          height: 32px;
          line-height: 22px;
          padding: 5px 10px;
          margin-right: 20px;
          margin-bottom: 12px;
          cursor: pointer;
        }

        &-item-selected {
          background: #F9F9FB;
          color: #333333;
        }
      }

      &-overflow {
        cursor: pointer;
        width: 100%;
        height: 33px;
        line-height: 33px;
        background: #F9F9FB;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>
